import to from 'await-to-js';
import { Component, EventEmitter, HostListener, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { SocketService } from '@services/socket.service';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { getUserPreferences } from '@services/userPref.service';
import { getFeatureFlags } from '@services/feature-flag.service';
import { App, setApp } from './services';
import { AdminAuthUser } from '@wearewarp/types/rest-api/admin';
import { AuthService, EventAuthUserChange, isOutdatedVersion } from '@wearewarp/ng-web';
import { ApiService } from '@services/api.service';
import { NavigationEndData, NavigationUrlData } from './interfaces';
import { Const } from '@const/Const';
import { UIHelper } from '@services/UIHelper';
import { BaseComponent } from '@abstract/BaseComponent';
import { Utils } from '@services/utils';
import { Log } from '@services/log';
import { MasterData } from '@services/master.data';
import { AppConst } from './const.generated';
import { AWSUtil } from '@services/aws-util';
import { Subscription } from 'rxjs';
import { requirePermissions, verifyPasswordExpired } from '@services/auth.check-role';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GlobalSearchComponent } from './components/global-search';
import { customMapboxConfig } from './admin/components/mapbox-static/custom-mapbox';
import mapboxgl from 'mapbox-gl';
import { EnvType } from '@env/type';
import { ReplaySessionService } from '@services/replay-session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.html'
})
@Injectable()
export class AppComponent implements App, OnDestroy {
  private authUser: AdminAuthUser|undefined;
  public firstLoading: boolean|'OK'|'NG';
  private activatedComponent;
  public windowResizeListener: EventEmitter<any> = new EventEmitter<any>();
  private pendingNavigationEndUrl;
  private _currentNavigationUrlData: NavigationUrlData;
  get currentNavigationUrlData(): NavigationUrlData { return this._currentNavigationUrlData }
  public navigationEnd = new EventEmitter<NavigationEndData>();

  readonly txtAppInitError = 'Cannot connect to the server, please try again later';
  public get currentUrl(): string { return this.router.url }

  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private api: ApiService,
    private auth: AuthService,
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private modalService: NzModalService,
    private replaySessionService: ReplaySessionService,
  ) {
    setApp(this);

    getUserPreferences(this.api, this.authUser)
    getFeatureFlags(this.api, this.authUser)

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.handleNavigationEnd(event);
      }
    });
    if (this.auth.alreadyLoggedIn()) {
      this.didLogin();
    }
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(() => {
      var rt = this.getChild(this.activatedRoute)
      rt.data.subscribe(data => {
        this.titleService.setTitle(data.title ?? 'WARP: Peace of mind for your middle mile freight')
      })
    });
    this.subscription.add(this.auth.authChanged.subscribe(ev => {
      switch (ev.code) {
        case EventAuthUserChange.login:
          return this.didLogin();
        case EventAuthUserChange.logout:
          return this.didLogout();
        default: return;
      }
    }));
    customMapboxConfig(mapboxgl);
    if (this.shouldSetupServer) {
      this.router.navigate(['/setup-server']);
    } else {
      this.firstInit();
    }
  }

  get shouldSetupServer(): boolean {
    return environment.type == EnvType.portable && !environment.backendUrl;
  }

  getCurrentNavigationUrlData() {
    return this.currentNavigationUrlData;
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.windowResizeListener.emit(event);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private handleNavigationEnd(event: NavigationEnd) {
    const shouldEnable2FA = environment.twofaRequired && 
                            this.authUser && !this.authUser.has2fa && 
                            window.location.pathname != Const.routeSetting2FA;
    if (shouldEnable2FA) {
      UIHelper.showWarning("2FA is required.", "You have not enabled 2FA. Please set up 2FA to connect to your phone for enhanced security.")
      return this.router.navigate([Const.routeSetting2FA]);
    }
    let data = this.createNavigationEndData();
    this._currentNavigationUrlData = Utils.cloneObject(data.current);
    this.navigationEnd.emit(data);
    if (!this.activatedComponent) {
      // App initialization has not completed yet so component has not been activated
      if (event.url.length > '/'.length) {
        this.pendingNavigationEndUrl = event.url;
      }
    } else if (this.activatedComponent instanceof BaseComponent) {
      // Only activatedComponent is allow to handle
      (<BaseComponent>this.activatedComponent).onNavigationEnd(event.url);
    }
  }

  createNavigationEndData(): NavigationEndData {
    let urlData: NavigationUrlData = {
      url: location.pathname + location.search,
      urlParams: this.activatedRoute.snapshot.params,
      queryParams: this.activatedRoute.snapshot.queryParams
    };
    return {previous: this._currentNavigationUrlData, current: urlData};
  }

  subscribeWindowResize(next) {
    return this.windowResizeListener.subscribe(next)
  }

  subscribeNavigationEnd(next) {
    return this.navigationEnd.subscribe(next);
  }

  getAuthUser() {
    return this.authUser;
  }
  
  public isFirstLoadingOK(): boolean {
    return this.firstLoading == 'OK';
  }
  
  protected firstInit() {
    this.firstLoading = true;
    this.initSession();
  }
  
  protected async firstInitDone(resp, err) {
    if (err) {
      this.firstLoading = 'NG';
      return UIHelper.showErr(err);
    }
    if (resp) {
      Log.d('firstInitDone ', resp);
      if (resp.data.masters) {
        let masterData = MasterData.getFromResponse(resp.data.masters);
        let latestVersion = masterData?.appsVersion?.web_admin?.version;
        Log.d('latestVersion: ', latestVersion);
        if (latestVersion) {
          let currentVersion = AppConst.Version;
          const key = 'tryToGetLatestVersion';
          if (isOutdatedVersion(currentVersion, latestVersion) && localStorage.getItem(key) != '1') {
            // Chỉ cần cố reload 1 lần thôi, tránh bị rơi vào trường hợp reload mãi
            localStorage.setItem(key, '1');
            return window.location.reload();
          } else {
            localStorage.removeItem(key);
          }
        }
        MasterData.set(masterData);
        if (masterData.aws) {
          AWSUtil.init(masterData.aws);
        }
      }
    }
    await this.getMyProfile();
    this.firstLoading = 'OK';
  }
  
  private initSession() {
    this.api.POST(Const.API_SESSION()).subscribe(
      resp => {
        this.firstInitDone(resp, null);
      }, err => {
        this.firstInitDone(null, err);
      }
    );
  }
  
  private async getMyProfile() {
    if (!this.auth.alreadyLoggedIn()) {
      return;
    }
    let [err, resp] = await to(this.api.GET(Const.APIURI_MY_PROFILE).toPromise());
    if (err) {
      return UIHelper.showErr(err);
    }
    this.authUser = resp.data;
    this.checkPasswordExpired(resp.data);
    this.setUserIdForMsClarity(this.authUser);
    getUserPreferences().setUser(this.authUser)
    getFeatureFlags().setUser(this.authUser)
    this.replaySessionService.setUser(this.authUser);
  }

  public checkPasswordExpired(data: any) {
    if(!data) return;
    return verifyPasswordExpired(data, this.router);
  }

  public async loginSucceeded() {
    await this.getMyProfile();
  }

  public async myProfileChanged() {
    await this.getMyProfile();
  }
  
  public scrollToTop() {
    window.scrollTo(0, 0);
  }

  onRouterActivate(compRef) {
    if(!this.hasPermissions()){
      this.router.navigate(['/']);
      return;
    }
    this.activatedComponent = compRef;
    if (compRef instanceof BaseComponent) {
      let comp: BaseComponent = compRef;
      comp.onRouterActivated();
      if (this.pendingNavigationEndUrl) {
        comp.onNavigationEnd(this.pendingNavigationEndUrl);
        this.pendingNavigationEndUrl = null;
      }
    }
  }

  hasPermissions() {
    const rt = this.getChild(this.activatedRoute)
    const permissions = rt?.snapshot?.data?.permissions;
    if(!permissions) return true;
    return requirePermissions(this.authUser, permissions);
  }

  onRouterDeactivate(compRef) {
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  private setUserIdForMsClarity(authUser) {
    const userId = `[${environment.type}] ${authUser.warpId}-${authUser.email}`;
    (<any>window)?.clarity("set", "userId", userId);
  }

  protected didLogin() {
    this.socketService.connect();
    this.checkPasswordExpired(this.authUser);
    getUserPreferences().setUser(this.authUser)
    getFeatureFlags().setUser(this.authUser);
  }

  protected didLogout() {
    this.socketService.disconnect();
    getUserPreferences().setUser(null)
    getFeatureFlags(this.api).setUser(null)
  }

  private lastShift: number = 0
  private searching: boolean = false
  private doubleShiftInterval = 300;  // ms
  readonly globalSearchEnabled = true;

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (!this.globalSearchEnabled) return;
    if (this.searching) return
    if (event.key != "Shift") {
      this.lastShift = 0
      return
    }
    const now = Date.now()
    if (now - this.lastShift > this.doubleShiftInterval) {
      this.lastShift = now
      return
    }
    this.lastShift = 0
    this.onGlobalSearch()
  }

  private onGlobalSearch() {
    this.searching = true
    this.modalService.create({
      nzContent: GlobalSearchComponent,
      nzTitle: null,
      nzFooter: null,
      nzOnCancel: () => { this.searching = false },
      nzOnOk: () => { this.searching = false },
      nzStyle: {
        'border-radius': '12px'
      }
    })
  }
}
